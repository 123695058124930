import styled from 'styled-components';
import { atMinWidth } from '../../atoms/breakpoints';
import { font } from '../../atoms/typography';

export default styled.div`
  width: 100%;

  .full-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      display: flex;
      justify-content: center;
    }
  }

  .hero-content {
    color: white;
    text-align: center;
    max-width: 700px;
    margin: 70px auto 30px;

    @media (max-width: 768px) {
      margin-top: 48px;
    }

    @media (max-width: 575px) {
      margin-top: 30px;
    }

    .icon {
      width: 100px;
      height: 100px;
      margin-bottom: 32px;
    }

    h1 {
      ${font('display', 'lg', '700')}
      color: ${props => props.color};

      ${atMinWidth.sm`
        ${font('display', 'xl', '700')}
      `}

      ${atMinWidth.sm`
        ${font('display', 'xxl', '700')}
      `}
    }

    p {
      margin-top: 16px;
    }

    .action-btns {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin-top: 32px;
    }
  }
`;
