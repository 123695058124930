import { globalHistory } from '@reach/router';
import React, { useMemo } from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import SuccessHeroStyles from '../../../styles/Components/Hero/SuccessHeroStyles';
import Icon from '../../../styles/atoms/icons';

const Success = () => {
  const params = new URLSearchParams(globalHistory.location.search);
  const support = params.get('support') === 'true';
  const raffle = params.get('raffle') === 'true';
  const showActionsButtons = !raffle;
  const showCaseStudiesButton = !support;

  const heading = useMemo(() => {
    if (support) {
      return 'Your request as been submitted!';
    }

    if (raffle) {
      return 'Your raffle entry has been submitted!';
    }

    return 'Thank you for your interest in Shopmonkey!';
  }, [raffle, support]);

  const body = useMemo(() => {
    if (support) {
      return 'A member of our team will be in touch within one business day.';
    }

    if (raffle) {
      return '';
    }

    return 'A member of our team will be in touch with you shortly.';
  }, [raffle, support]);

  return (
    <SuccessHeroStyles>
      <Section className="full-section" backgroundImageName="blue-hex">
        <div className="hero-content">
          <Icon className="icon" id="checkmark" isImage />
          <h1>{heading}</h1>
          {body && <p>{body}</p>}
          {showActionsButtons && (
            <div className="action-btns">
              <CallToAction
                link="/"
                variant="secondary"
                value="Go to Homepage"
              />
              {showCaseStudiesButton && (
                <CallToAction
                  link="/case-studies"
                  variant="outlineWhite"
                  value="Read Case Studies"
                />
              )}
            </div>
          )}
        </div>
      </Section>
    </SuccessHeroStyles>
  );
};

export default Success;
