import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Success from '../components/Hero/variants/SuccessHero';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GeneralHead from '../components/generalHead';

const ThankYouPage = () => (
  <>
    <Header isDark />
    <Success />
    <Footer />
  </>
);

export const Head = ({
  data: {
    thankYou: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query ThankYouQuery {
    thankYou: datoCmsThankYou {
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default ThankYouPage;
